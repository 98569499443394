.avatar {
    display: flex;
    align-items: center;
    padding-left: 14px;

    &-text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: #fff;
        margin-left: 10px;

        p {
            font-size: 14px;
            margin-bottom: 0;
        }
    }
}
