@import "../../../assets/styles/theme";


.table-row-right-clicked {
    background-color: $active-color !important;
}

.ant-table-thead {
    border-radius: 4px !important;
    th {
        background-color: $pimary-background-color !important;
        color: $primary-text-color !important;

        .ant-table-filter-trigger:hover {
            color: rgba(255, 255, 255, 0.45) !important;
            background: rgba(255, 255, 255, 0.04) !important;
        }
    }

    th:last-child {
        border-top-right-radius: 4px !important;
    }
    th:first-child {
        border-top-left-radius: 4px !important;
    }
}

.ant-table-expanded-row-level-1 {
    th {
        //background-color: rgb(1, 92, 177)!important;
        background-color: #3BAFC7 !important;
        color: $primary-text-color !important;

        .ant-table-filter-trigger:hover {
            color: rgba(255, 255, 255, 0.45) !important;
            background: rgba(255, 255, 255, 0.04) !important;
        }
    }
}

.ant-table-expanded-row-level-1 .ant-table-summary {
    background-color: #f6f7f8 !important;
}

.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin: -12px -8px -12px 25px;
}