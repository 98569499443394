.inherited {
  background-color: rgb(0, 234, 255);
}

  .custom-tag {
    border-radius: 4px;
    font-weight: 600;
    color: #fff;
    background-color: #2db7f5;
    border-color: #2db7f5;
  }
  
  .custom-tag:hover {
    background-color: #1890ff;
    border-color: #1890ff;
  }
  