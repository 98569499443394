.segmented-container{
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px;

position: relative;
width: 160px;
height: 28px;

border-radius: 4px;
}

.ant-segmented{
    border-radius:4px;
    background-color: white;
   
}

.ant-segmented-item-selected{
    background: #4489F7;
    color: white;
  }

  .ant-btn-primary {
    margin-right: 16px;
    border-radius: 4px;
    background-color: #96BF3D;
    border-color: #96BF3D;
  }
  
  .ant-btn-primary:hover {
    background-color: #7cac32;
    border-color: #7cac32;
  }

  .ant-btn-primary:focus {
    background-color: #96BF3D;
    border-color: #96BF3D;
  }
  
  .reset-button {
    margin-right: 16px;
    border-radius: 4px;
    background-color: white;
    border-color: #d9d9d9;
  }
  
  .reset-button:hover {
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }
  


  

