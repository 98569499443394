.card-container {
  display: flex;
  align-items: flex-start
}

.ant-row {
  display: flex;
  width: 100%; /* Make the row fill the available width */
}

.ant-col {
  flex: 1;
}

.ant-form-item-label > label {
  color: #485465;
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 600;
  line-height: 18.20px;
  word-wrap: break-word;
}
