.app-sider {
    position: relative !important;
    width: 240px !important;
}

.ant-menu-submenu-title {
    padding-left: 12px !important;
}

.ant-menu-submenu-vertical {
    .ant-menu-submenu-title {
        margin-left: 24px !important;
    }
}
