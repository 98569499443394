@import "./assets/styles/_theme.scss";

.ant-collapse-header {
    background-color: $pimary-background-color !important;
    color: #fff !important;
}

.ant-card-head {
    background-color: #e6e9ec  !important;
    // color: #fff !important;
}

body {
    font-family: "'Open Sans', sans-serif";
}

