@import '../../../../../assets/styles/theme';

.ant-popover-inner-content {
    width: 700px !important;
    height: 300px !important;
    overflow-y: scroll;
}

.ant-popover-title {
    background-color: $pimary-background-color !important;
    color: $primary-text-color !important;
}

.notification-list {
    display: flex;
    align-self: center;
    background-color: #002140;
    cursor: pointer;
    padding-left: 14px;
    margin-bottom: 15px;
    
    p {
        color: #fff;
        margin-left: 10px;
        align-self: center;
    }
}