@import "../../../../assets/styles/theme";

.ant-modal {
  width: 600px;

  .ant-modal-content {
    border-radius: 4px;
  }

  .ant-modal-header {
    color: #fff;
    border-radius: 8px 8px 0 0;
  }


}

.report-content {
  padding: 14px;

  .list-header {
    font-weight: bold;
    margin-bottom: 6px;
  }

}

.copy-button {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 6px 10px;
  background-color: #002140;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

}
