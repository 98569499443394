.jobs-transfer {
    .ant-transfer-list {
        width: 370px !important;
    }

    .ant-transfer-list-content-item {
        align-items: baseline;
    }
}


